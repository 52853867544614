import { Controller } from "stimulus";
import loggedIn from "../lib/loggedIn";

export default class extends Controller {
  connect() {
    if (loggedIn()) {
      this.element.classList.add("person-signed-in");
    }
  }
  toggle() {
    this.element.classList.toggle("show-nav");
  }
}
