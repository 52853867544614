export function latLng(obj: Positioned): google.maps.LatLngLiteral {
  return {
    lat: parseFloat(`${obj.latitude}`),
    lng: parseFloat(`${obj.longitude}`)
  };
}

export function haversine(
  pos1: google.maps.LatLngLiteral,
  pos2: google.maps.LatLngLiteral
) {
  const rlat1 = pos1.lat * (Math.PI / 180);
  const rlat2 = pos2.lat * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (pos2.lng - pos1.lng) * (Math.PI / 180);

  return (
    2 *
    6371.071 * // Radius of the Earth in km
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    )
  );
}

export function parseQuantizedPositions<T extends Positioned>(objs: T[]): T[] {
  const positions: google.maps.LatLngLiteral[] = [];

  return objs.map((o) => {
    let position = latLng(o);

    const close = positions.filter((p) => haversine(p, position) <= 0.01);
    if (close.length > 0) {
      position = close[0];
    } else {
      positions.push(position);
    }

    return { ...o, position: position };
  });
}

export function parsePositions<T extends Positioned>(objs: T[]): T[] {
  return objs.map((o) => {
    return { ...o, position: latLng(o) };
  });
}
