import { ChangeEvent, useState } from "react";
import ArtistList from "./ArtistList";

interface Props {
  artists: Artist[];
  strings: Localizations;
}

function filterArtists(artists: Artist[], day: string, search: string) {
  if (day) {
    artists = artists.filter((a) => a.day === day);
  }

  if (search && search.length >= 2) {
    artists = artists.filter((a) => fuzzyMatch(searchString(a), search));
  }
  return artists;
}

function fuzzyMatch(str: string, query: string) {
  const searchStr = str.toLowerCase();
  const terms = query.toLowerCase().split(/\s+/);
  const results = terms
    .map((t) => new RegExp(t).test(searchStr))
    .filter((r) => r);
  return results.length == terms.length;
}

function randomize(artists: Artist[]) {
  // Fisher-Yates
  const array = [...artists];
  let m = array.length,
    t: Artist,
    i: number;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
}

function searchString(a: Artist) {
  return [a.name, a.technique, a.full_address, a.description]
    .filter((s) => s)
    .join(" ");
}

export default function ArtistsIndex(props: Props) {
  const { artists, strings } = props;
  const [day, setDay] = useState("");
  const [search, setSearch] = useState("");
  const [randomized, setRandomized] = useState(true);
  const [randomizedArtists, setRandomizedArtists] = useState(
    randomize(artists)
  );

  const filteredArtists = filterArtists(
    randomized ? randomizedArtists : artists,
    day,
    search
  );

  const handleDay = (day: string) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    setDay(day);
  };

  const handleSearch = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value);
  };

  const handleRandomized = (value: boolean) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (value) {
      setRandomizedArtists(randomize(artists));
    }
    setRandomized(value);
  };

  return (
    <div className="artists-index">
      <nav className="subnav">
        <input
          name="search"
          className="search"
          value={search}
          onChange={handleSearch}
          placeholder={strings.artists_search}
        />
        <ul>
          <li>
            <a
              href="#"
              className={day == "" ? "current" : ""}
              onClick={handleDay("")}>
              {strings.all_artists}
            </a>
          </li>
          <li>
            <a
              href="#"
              className={day == "saturday" ? "current" : ""}
              onClick={handleDay("saturday")}>
              {strings.open_saturday}
            </a>
          </li>
          <li>
            <a
              href="#"
              className={day == "sunday" ? "current" : ""}
              onClick={handleDay("sunday")}>
              {strings.open_sunday}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              href="#"
              className={randomized ? "current" : ""}
              onClick={handleRandomized(true)}>
              {strings.random_order}
            </a>
          </li>
          <li>
            <a
              href="#"
              className={!randomized ? "current" : ""}
              onClick={handleRandomized(false)}>
              {strings.alphabetical}
            </a>
          </li>
        </ul>
      </nav>
      <div className="list">
        {filteredArtists.length > 0 && (
          <ArtistList artists={filteredArtists} strings={strings} />
        )}
        {filteredArtists.length == 0 && <p>{strings.sorry_no_results}</p>}
      </div>
    </div>
  );
}
