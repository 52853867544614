import { useCallback, useMemo, ReactNode } from "react";
import { renderToString } from "react-dom/server";

export default function useInfoWindow() {
  const infoWindow = useMemo(() => {
    return new google.maps.InfoWindow({
      minWidth: 330,
      pixelOffset: new google.maps.Size(0, -25)
    });
  }, []);

  const open = useCallback(
    (
      content: ReactNode,
      map: google.maps.Map,
      position: google.maps.LatLng
    ) => {
      infoWindow.setContent(renderToString(content));
      infoWindow.setPosition(position);
      infoWindow.open({ map: map });
    },
    [infoWindow]
  );

  return open;
}
