import $ from "jquery";

export default class {
  container: HTMLElement;
  geocoder: google.maps.Geocoder;
  guessedPosition: boolean;
  guessInterval: ReturnType<typeof setTimeout>;
  latField: HTMLInputElement;
  lngField: HTMLInputElement;
  map: google.maps.Map;
  marker: google.maps.Marker | null;
  position: google.maps.LatLng | false;

  constructor(container: HTMLElement) {
    this.container = container;
    const form = $(this.container).closest("form");
    const addressFields = form.find(".address");
    this.latField = form.find(".latitude").get(0) as HTMLInputElement;
    this.lngField = form.find(".longitude").get(0) as HTMLInputElement;

    if (this.latField.value && this.lngField.value) {
      this.position = new window.google.maps.LatLng(
        parseFloat(this.latField.value),
        parseFloat(this.lngField.value)
      );
    } else {
      this.position = false;
    }
    this.guessedPosition = false;
    this.geocoder = new window.google.maps.Geocoder();
    this.draw();

    addressFields.bind("change, keyup", () => {
      if (form.find("#artist_address")) {
        const values = addressFields.get().map((a: HTMLInputElement) => {
          return a.value.trim();
        });
        const address = values.join(" ") + " Norway";
        this.guess(address);
      }
    });

    form.find(".clearPosition").click((evt) => {
      evt.preventDefault();
      this.clearPosition();
    });
  }

  defaultOptions() {
    return {
      zoom: 11,
      scrollwheel: true,
      center: new window.google.maps.LatLng(59.91, 10.75),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      MapTypeControlOptions: {
        MapTypeIds: [window.google.maps.MapTypeId.ROADMAP]
      }
    };
  }

  guess(address: string) {
    if (!this.position || this.guessedPosition) {
      clearInterval(this.guessInterval);
      this.guessInterval = setInterval(() => {
        clearInterval(this.guessInterval);
        void this.geocoder.geocode({ address: address }, (results) => {
          if (results[0] && results[0].geometry) {
            this.guessedPosition = true;
            const latLng = results[0].geometry.location;
            this.createMarker(latLng);
            this.setPosition(latLng);
            this.map.panTo(latLng);
            if (this.map.getZoom() < 15) {
              this.map.setZoom(15);
            }
          }
        });
      }, 500);
    }
  }

  clearPosition() {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
    this.position = false;
    this.latField.value = "";
    this.lngField.value = "";
  }

  setPosition(latLng: google.maps.LatLng) {
    if (!this.position) {
      $(this.container).removeClass("disabled");
      $(".map_explanation").removeClass("disabled");
      window.google.maps.event.trigger(this.map, "resize");
    }
    this.position = latLng;
    this.marker.setPosition(latLng);
    this.latField.value = `${latLng.lat()}`;
    this.lngField.value = `${latLng.lng()}`;
  }

  createMarker(latLng: google.maps.LatLng) {
    if (!this.marker) {
      this.marker = new window.google.maps.Marker({
        position: latLng,
        map: this.map,
        draggable: true,
        animation: window.google.maps.Animation.DROP
      });
      window.google.maps.event.addListener(
        this.marker,
        "dragend",
        (e: google.maps.MapMouseEvent) => {
          this.guessedPosition = false;
          this.setPosition(e.latLng);
        }
      );
    }
  }

  draw() {
    this.map = new window.google.maps.Map(
      this.container,
      this.defaultOptions()
    );
    $(document).bind("mapToggle", () => {
      return window.google.maps.event.trigger(this.map, "resize");
    });

    if (this.position) {
      this.map.setZoom(14);
      this.map.setCenter(this.position);
      this.createMarker(this.position);
    } else {
      $(this.container).addClass("disabled");
      $(".map_explanation").addClass("disabled");
    }

    window.google.maps.event.addListener(
      this.map,
      "click",
      (e: google.maps.MapMouseEvent) => {
        if (!this.position) {
          this.createMarker(e.latLng);
          this.setPosition(e.latLng);
          setTimeout(() => {
            if (this.map.getZoom() < 14) {
              this.map.setZoom(14);
            }
            this.map.panTo(e.latLng);
          }, 400);
        }
      }
    );
  }
}
