import { ChangeEvent } from "react";
import useListsStore, {
  addToList,
  inList,
  removeFromList
} from "../../stores/useListsStore";

interface Props {
  artist: Artist;
}

export default function Lists(props: Props) {
  const { artist } = props;

  const lists = useListsStore((state) => state.lists);

  const handleChange = (list: List) => (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      addToList(list, artist);
    } else {
      removeFromList(list, artist);
    }
  };

  if (lists.length == 0) {
    return "";
  }

  return (
    <ul className="lists">
      {lists.map((list) => (
        <li key={list.name}>
          <label>
            <input
              type="checkbox"
              checked={inList(list, artist)}
              onChange={handleChange(list)}
            />{" "}
            {list.name}
          </label>
        </li>
      ))}
    </ul>
  );
}
