import { createContext, useContext } from "react";
import useInfoWindow from "./useInfoWindow";

type Context = {
  assets: MapAssets;
  strings: Record<string, string>;
  openInfoWindow: ReturnType<typeof useInfoWindow>;
  map?: google.maps.Map;
};

export const MapContext = createContext<Context>(null);

export default function useMapContext() {
  return useContext(MapContext);
}
