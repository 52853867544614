import loggedIn from "../../lib/loggedIn";

import Lists from "./Lists";
import LoginNotice from "./LoginNotice";
import NewList from "./NewList";

interface Props {
  artist: Artist;
  strings: Localizations;
}

export default function ListsUI(props: Props) {
  const { artist, strings } = props;

  if (!loggedIn()) {
    return <LoginNotice strings={strings} />;
  }

  return (
    <div className="add-to-lists">
      <h3>{strings.add_artist_to_list}</h3>
      <Lists artist={artist} />
      <NewList artist={artist} strings={strings} />
    </div>
  );
}
