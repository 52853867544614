import UserMap from "./UserMap";
import $ from "jquery";

$(function () {
  $(".user.map").each(function () {
    new UserMap(this);
  });

  function checkArtistType() {
    const val = $("input.artist_type:checked").val();
    if (val === "artist" || val === "collective_artist") {
      $(".studio").removeClass("disabled");
      $(document).trigger("mapToggle");
      $(".studio input").prop("disabled", false);
    } else {
      $(".studio").addClass("disabled");
      $(".studio input").prop("disabled", true);
    }
    if (val === "collective_artist") {
      $(".studio_collective input").prop("disabled", false);
    } else {
      $(".studio_collective input").prop("disabled", true);
    }
    if (val === "student") {
      $(".school").show();
    } else {
      $(".school").hide();
    }
  }

  if ($("input.artist_type").length > 0) {
    checkArtistType();
  }

  $("input.artist_type").change(function () {
    checkArtistType();
  });

  $(".field .description").each(function () {
    const label = $(this).closest(".field").find("label");
    label.append('<span class="description">' + $(this).text() + "</span>");
    $(this).remove();
  });
});
