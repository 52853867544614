import { useState } from "react";

import { createList } from "../../stores/useListsStore";

interface Props {
  artist: Artist;
  strings: Localizations;
}

export default function NewList(props: Props) {
  const { artist, strings } = props;
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setName(evt.target.value);
  };

  const performSubmit = async () => {
    setDisabled(true);
    await createList(name, artist);
    setName("");
    setDisabled(false);
  };

  const handleSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();

    if (name) {
      void performSubmit();
    }
  };

  return (
    <div className="new-list">
      <h4>{strings.new_list}:</h4>
      <form className="oneline" onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          disabled={disabled}
          onChange={handleChange}
        />
        <button type="submit" disabled={disabled}>
          {strings.create_list}
        </button>
      </form>
    </div>
  );
}
