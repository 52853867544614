import { create } from "zustand";

interface ModalState {
  component: JSX.Element | null;
}

const useModalStore = create<ModalState>(() => ({
  component: null
}));

export function closeModal() {
  useModalStore.setState(() => ({ component: null }));
}

export function openModal(component: JSX.Element) {
  useModalStore.setState(() => ({ component: component }));
}

export default useModalStore;
