export default function loggedIn(): boolean {
  const cookies = document.cookie
    .split(/;\s*/)
    .map((c) => c.split("="))
    .reduce((obj, c) => {
      obj[c[0]] = c[1];
      return obj;
    }, {});

  return cookies["person_signed_in"] ? true : false;
}
