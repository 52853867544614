import { Fragment } from "react";
import useLightboxStore, { closeLightbox } from "../stores/useLightboxStore";

export default function Lightbox() {
  const image = useLightboxStore((state) => state.image);

  if (image) {
    const { alt, fullsize, caption } = image;
    return (
      <div className="lightbox" onClick={closeLightbox}>
        <div className="inner">
          <div className="buttons">
            <button onClick={closeLightbox}>Close</button>
          </div>
          <div className="image">
            <img src={fullsize} alt={alt} />
          </div>
          {caption && (
            <div className="caption">
              <figcaption dangerouslySetInnerHTML={{ __html: caption }} />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <Fragment />;
  }
}
