import { useEffect, useReducer } from "react";
import Artist from "./ArtistList/Artist";
import MoveButtons from "./ArtistList/MoveButtons";
import { fetchLists } from "../stores/useListsStore";

interface Props {
  artists: Artist[];
  list: List;
  strings: Localizations;
}

function reducer(state: Artist[], action: Lists.Action) {
  const index = state.indexOf(action.payload);
  switch (action.type) {
    case "moveUp":
      return [
        ...state.slice(0, index - 1),
        state[index],
        state[index - 1],
        ...state.slice(index + 1, state.length)
      ];
    case "moveDown":
      return [
        ...state.slice(0, index),
        state[index + 1],
        state[index],
        ...state.slice(index + 2, state.length)
      ];
    default:
      return state;
  }
}

export default function EditListArtists(props: Props) {
  const { list, strings } = props;

  const [artists, dispatch] = useReducer(reducer, props.artists);

  useEffect(() => {
    void fetchLists();
  }, []);

  return (
    <div className="artists-list edit-artist-list">
      {artists.map((a) => (
        <Artist key={a.id} artist={a} strings={strings}>
          <MoveButtons
            artist={a}
            artists={artists}
            dispatch={dispatch}
            list={list}
            strings={strings}
          />
        </Artist>
      ))}
    </div>
  );
}
