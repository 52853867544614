import { create } from "zustand";
import loggedIn from "../lib/loggedIn";

interface ListsState {
  csrfToken: string | null;
  loaded: boolean;
  lists: List[];
}

interface ListsResponse {
  csrfToken: string;
  lists: List[];
}

type ListParams = {
  name?: string;
  artist_ids?: number[];
};

const useListsStore = create<ListsState>(() => ({
  csrfToken: null,
  lists: [],
  loaded: false
}));

export async function createList(name: string, artist?: Artist) {
  const list = await saveList({ name: name, artists: artist ? [artist] : [] });

  if (list) {
    useListsStore.setState((state) => ({ lists: [...state.lists, list] }));
  }
}

export async function fetchLists() {
  if (loggedIn()) {
    const response = await fetch("/nb/lists.json");
    const json = (await response.json()) as ListsResponse;

    useListsStore.setState(() => ({ ...json, loaded: true }));
  } else {
    useListsStore.setState(() => ({ loaded: true }));
  }
}

export function inList(list: List, artist: Artist) {
  return list.artists.filter((a) => a.id == artist.id).length > 0;
}

export function inAnyList(artist: Artist) {
  const lists = useListsStore.getState().lists;

  return lists.filter((l) => inList(l, artist)).length > 0;
}

export function addToList(list: List, artist: Artist) {
  if (inList(list, artist)) {
    return;
  }
  updateList(list.id, { artists: [...list.artists, artist] });
}

export function removeFromList(list: List, artist: Artist) {
  updateList(list.id, {
    artists: list.artists.filter((a) => a.id !== artist.id)
  });
}

function listParams(list: Partial<List>): ListParams {
  const params: ListParams = {};
  if (list.name) {
    params.name = list.name;
  }
  if (list.artists) {
    params.artist_ids = list.artists.map((a) => a.id);
  }
  return params;
}

async function saveList(list: Partial<List>) {
  const url = list.id ? `/nb/lists/${list.id}.json` : "/nb/lists.json";

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": useListsStore.getState().csrfToken
    },
    method: list.id ? "PUT" : "POST",
    body: JSON.stringify({ list: listParams(list) })
  });

  if (response.status == 200) {
    return response.json() as Promise<List>;
  } else {
    return;
  }
}

function updateList(id: string, attributes: Partial<List>) {
  useListsStore.setState((state) => ({
    lists: state.lists.map((l) => {
      return l.id == id ? { ...l, ...attributes } : l;
    })
  }));
  void saveList({ id: id, ...attributes });
}

export default useListsStore;
