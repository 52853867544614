import { Fragment, useEffect, useState } from "react";
import Marker from "./Marker";
import useMapContext from "./useMapContext";

export default function UserPosition() {
  const { assets } = useMapContext();

  const [position, setPosition] = useState<google.maps.LatLngLiteral>(null);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition((p) => {
      setPosition({ lat: p.coords.latitude, lng: p.coords.longitude });
    });
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const icon = {
    url: assets.location,
    size: new google.maps.Size(20, 20),
    scaledSize: new google.maps.Size(20, 20)
  };

  return (
    <Fragment>
      {position && (
        <Marker position={position} icon={icon} title="Your location" />
      )}
    </Fragment>
  );
}
