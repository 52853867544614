interface Props {
  position: google.maps.LatLng;
  strings: Record<string, string>;
}

export default function DirectionsPopup({
  children,
  position,
  strings
}: React.PropsWithChildren<Props>) {
  const dirUrl = `https://www.google.com/maps/dir/?api=1&destination=${position.lat()}%2C${position.lng()}`;

  return (
    <div className="directions-popup">
      <div className="directions">
        <a href={dirUrl}>{strings.directions}</a>
      </div>
      <div className="popup-content">{children}</div>
    </div>
  );
}
