import {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import ArtistMarkers from "./Map/ArtistMarkers";
import ArtistsPopup from "./Map/ArtistsPopup";
import SponsorMarkers from "./Map/SponsorMarkers";
import SponsorsPopup from "./Map/SponsorsPopup";
import UserPosition from "./Map/UserPosition";
import useInfoWindow from "./Map/useInfoWindow";
import { MapContext } from "./Map/useMapContext";
import { parsePositions, parseQuantizedPositions } from "./Map/utils";

type ZoomState =
  | null
  | { type: "artist"; record: Artist }
  | { type: "sponsor"; record: SponsorLocation };

interface Props {
  artists: Artist[];
  strings: Record<string, string>;
  assets: MapAssets;
  sponsors: SponsorLocation[];
}

function getZoomTarget(
  artists: Artist[],
  sponsors: SponsorLocation[]
): ZoomState {
  let target: ZoomState = null;
  if (document?.location?.hash) {
    if (document.location.hash.match(/sponsor-location-([\d]+)/)) {
      const id = parseInt(document.location.hash.match(/-([\d]+)/)[1], 10);
      sponsors.forEach((s) => {
        if (s.id === id) {
          target = { type: "sponsor", record: s };
        }
      });
    } else if (document.location.hash.match(/artist-([\d]+)/)) {
      const id = parseInt(document.location.hash.match(/-([\d]+)/)[1], 10);
      artists.forEach((a) => {
        if (a.id === id) {
          target = { type: "artist", record: a };
        }
      });
    }
  }
  return target;
}

export default function ArtistsMap(props: Props) {
  const { assets, strings } = props;

  const artists = useMemo(
    () => parseQuantizedPositions(props.artists),
    [props.artists]
  );

  const sponsors = useMemo(
    () => parsePositions(props.sponsors),
    [props.sponsors]
  );

  const containerRef = useRef<HTMLDivElement>();
  const mapRef = useRef<google.maps.Map>(null);
  const [ready, setReady] = useState(false);
  const [showSaturday, setShowSaturday] = useState(true);
  const [showSunday, setShowSunday] = useState(true);
  const openInfoWindow = useInfoWindow();

  useEffect(() => {
    const zoomTarget = getZoomTarget(artists, sponsors);

    mapRef.current = new google.maps.Map(containerRef.current, {
      zoom: zoomTarget ? 15 : 11,
      scrollwheel: true,
      center: zoomTarget
        ? zoomTarget.record.position
        : new window.google.maps.LatLng(59.91, 10.75),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      maxZoom: 18
    });
    google.maps.event.addListenerOnce(mapRef.current, "idle", () => {
      if (zoomTarget) {
        const pos = new google.maps.LatLng(zoomTarget.record.position);
        if (zoomTarget.type === "artist") {
          openInfoWindow(
            <ArtistsPopup
              artists={[zoomTarget.record]}
              position={pos}
              strings={strings}
            />,
            mapRef.current,
            pos
          );
        } else if (zoomTarget.type === "sponsor") {
          openInfoWindow(
            <SponsorsPopup
              sponsors={[zoomTarget.record]}
              position={pos}
              strings={strings}
            />,
            mapRef.current,
            pos
          );
        }
      }
    });
    setReady(true);
    return () => {
      mapRef.current = null;
      setReady(false);
    };
  }, [artists, sponsors, strings, openInfoWindow]);

  const toggleSaturday = (evt: ChangeEvent<HTMLInputElement>) => {
    setShowSaturday(evt.target.checked);
  };

  const toggleSunday = (evt: ChangeEvent<HTMLInputElement>) => {
    setShowSunday(evt.target.checked);
  };

  return (
    <MapContext.Provider
      value={{
        assets: assets,
        strings: strings,
        openInfoWindow: openInfoWindow,
        map: mapRef.current
      }}>
      <div className="artists-map">
        <div className="legend">
          <label className="saturday">
            <input
              type="checkbox"
              checked={showSaturday}
              onChange={toggleSaturday}
            />
            {strings.open_saturday}
          </label>
          <label className="sunday">
            <input
              type="checkbox"
              checked={showSunday}
              onChange={toggleSunday}
            />
            {strings.open_sunday}
          </label>
        </div>

        <div className="map" ref={containerRef} />
        {ready && (
          <Fragment>
            <UserPosition />
            {showSaturday && (
              <ArtistMarkers
                day="saturday"
                artists={artists.filter((a) => a.day == "saturday")}
              />
            )}
            {showSunday && (
              <ArtistMarkers
                day="sunday"
                artists={artists.filter((a) => a.day == "sunday")}
              />
            )}
            <SponsorMarkers sponsors={sponsors} />
          </Fragment>
        )}
      </div>
    </MapContext.Provider>
  );
}
